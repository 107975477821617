// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "x_c6";
export var productDiscoveryAchievementsSection = "x_dj";
export var productDiscoveryBenefitsSection = "x_c5";
export var productDiscoveryCaseSection = "x_dd";
export var productDiscoveryClientSection = "x_dk";
export var productDiscoveryIndustriesSection = "x_c4";
export var productDiscoveryOverviewSection = "x_db";
export var productDiscoveryProcessSection = "x_c9";
export var productDiscoveryResultsSection = "x_df";
export var productDiscoveryRolesSection = "x_dc";
export var productDiscoveryServicesSection = "x_c3";
export var productDiscoveryStagesSection = "x_c7";
export var productDiscoverySuccessStoriesSection = "x_dg";
export var productDiscoveryWhyChooseSection = "x_dh";
export var productDiscroveryImportanceSection = "x_c8";